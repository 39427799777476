import { graphql } from "gatsby"
import React from "react"
import Layout from "../layouts/Layout"
import Cards from "../components/Cards"
import Newsletter from "../components/Newsletter"
import SiteMetadata from "../components/SiteMetadata"

const BlogPage = ({ data }) => (
  <Layout>
    <SiteMetadata
      title="Blog"
      description="The blog of Benevolent Senior Services"
    />

    <div className="bg-pacblue-light py-12 lg:py-16 h-screen">
      {data.article && data.article.nodes.length > 0 ? (
        <Cards items={data.article.nodes} />
      ) : (
        <div className="container">No articles found.</div>
      )}
    </div>

    {/*<Newsletter />*/}
  </Layout>
)

export default BlogPage

export const query = graphql`
  query BlogQuery {
    article: allContentfulArticle {
      nodes {
        ...ArticleCard
      }
    }
  }
`
